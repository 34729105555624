<template>
  <div id="progressMeter">
    <ul id="info">
        <li @mouseenter="one=true" @mouseleave="one=false" :class="[{activeArrow: stageActive.includes('1')},{hoverDesc: one}]">
            <p>
            Schematic plans and elevations are reviewed. Design drawings and specifications are complete
            </p>
            <h4>Design Development</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="two=true" @mouseleave="two=false" :class="[{activeArrow: stageActive.includes('2')},{hoverDesc: two}]">
            <p>
            Official approvals issuance to ensure compliance with governmental regulations standards
            </p>
            <h4>Permits</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="three=true" @mouseleave="three=false" :class="[{activeArrow: stageActive.includes('3')},{hoverDesc: three}]">
            <p>
            Formal biding for contractors
            </p>
            <h4>Tendering</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="four=true" @mouseleave="four=false" :class="[{activeArrow: stageActive.includes('4')},{hoverDesc: four}]">
            <p>
            Base construction establishment including; concrete,  and building foundation 
            </p>
            <h4>Grading & Structure Backfilling</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="five=true" @mouseleave="five=false" :class="[{activeArrow: stageActive.includes('5')},{hoverDesc: five}]">
            <p>
            Upward extension of an existing <strong>structure</strong> including concrete, brick structure and meta
            </p>
            <h4>Super Structure</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="six=true" @mouseleave="six=false" :class="[{activeArrow: stageActive.includes('6')},{hoverDesc: six}]">
            <p>
            Concluding stage of <strong>construction</strong>;  including entrances, frontages, stairs core 
            </p>
            <h4>Finishing Works</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="seven=true" @mouseleave="seven=false" :class="[{activeArrow: stageActive.includes('7')},{hoverDesc: seven}]">
            <!-- <p>
            Concluding stage of <strong>construction</strong>;  including entrances, frontages, stairs core 
            </p> -->
            <h4>Units Handing Over</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="eight=true" @mouseleave="eight=false" :class="[{activeArrow: stageActive.includes('8')},{hoverDesc: eight}]">
            <p>
            The foundation system of the project including electricity, cables, gas and water
            </p>
            <h4>Infrastructure</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="nine=true" @mouseleave="nine=false" :class="[{activeArrow: stageActive.includes('9')},{hoverDesc: nine}]">
            <p>
            Base course for roads, walkways, promenades, parking decks and driveways
            </p>
            <h4>Roads & Hardscape</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="ten=true" @mouseleave="ten=false" :class="[{activeArrow: stageActive.includes('10')},{hoverDesc: ten}]">
            <p>
            Includes all greenery of the project such as flowers, plants, soil, grass and trees
            </p>
            <h4>Softscape</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
        <li @mouseenter="eleven=true" @mouseleave="eleven=false" :class="[{activeArrow: stageActive.includes('11')},{hoverDesc: eleven}]">
            <p>
            The client’s formal receiving of their unit and acceptance of its condition
            </p>
            <h4>Handing Over & Operation</h4>
            <span><img src="@/assets/images/icons/arrow-triangle.svg"/></span>
        </li>
    </ul>
    <ul id="card">
      <li>1</li>
      <li>2</li>
      <li>3</li>
      <li>4</li>
      <li>5</li>
      <li>6</li>
      <li>7</li>
      <li>8</li>
      <li>9</li>
      <li>10</li>
      <li>11</li>
    </ul>
    <ul id="line-indicator">
      <li class="line-svg"><img src="@/assets/images/line.svg"/></li>
      <li><img src="@/assets/images/icons/home.svg"/></li>
      <li>Your MV Home ♥</li>
    </ul>
  </div>

</template>
<script>
export default {
    data(){
        return{
            one:false,
            two:false,
            three:false,
            four:false,
            five:false,
            six:false,
            seven:false,
            eight:false,
            nine:false,
            ten:false,
            eleven:false

        }
  },
  props:{
      stageActive: {
                type: Array,
                required: true 
        },
  }
};
</script>
<style scoped>
div {
  display: flex;
}
#card{
    border-radius: 5px;
    border: 3px solid white;
}
#card li {
  width: 100%;
  height: 6.2vh;
  background-color: red;
  border-bottom: 3px solid white;
  color: transparent;
  
}
#card li:nth-child(1) {
  background-color: #ffd5a5;
}
#card li:nth-child(2) {
  background-color: #e9cfab;
}
#card li:nth-child(3) {
  background-color: #d1c9b2;
}
#card li:nth-child(4) {
  background-color: #b7c2ba;
}
#card li:nth-child(5) {
  background-color: #9bbcc1;
}
#card li:nth-child(6) {
  background-color: #82b5c8;
}
#card li:nth-child(7) {
  background-color:#65A9C2;
}
#card li:nth-child(8) {
  background-color: #549FC2;
}
#card li:nth-child(9) {
  background-color: #3987AB;
}
#card li:nth-child(10) {
  background-color: #206686;
}
#card li:nth-child(11) {
  background-color: #1C526B;
  border:0;
}

#info {
  position: relative;
  left: 1vw;
}
#info li{
  width: 100%;
  height: 6.2vh;
  display: flex;
  align-items: center;
  cursor: pointer;
}
#info img{
    width:1.3rem;
    height:auto;
}
#info h4{
    font-size: 0.75rem;
    width: 70%;
    margin:0;
    margin-right: 15px;
    line-height: 0.9rem;
    text-align: center;
}
#info span{
    opacity:0;
}
#info p{
  font-size: 0.8rem;
  position: absolute;
  opacity:0;
  background:white;
  padding:12px;
  margin-top:10px;
  border-radius: 10px;
  width:300%;
  left:-320%;
  display:block;
  z-index: -1000;
}
.activeArrow h4{font-weight: 800;}
.activeArrow h4:hover{font-size:0.9rem !important;line-height: 1.1rem;}
.activeArrow span{
    opacity:1 !important;
}
.hoverDesc p{opacity:1 !important;z-index:10 !important;}

#line-indicator{
  display:flex;
  flex-direction: column;
  align-items: center;
  text-align:center;
  justify-content: center;
  font-size:0.8rem;
  font-weight:600;
  line-height:0.9rem;
}
#line-indicator .line-svg img{
  height:60vh;
  width:auto;
}

</style>