<template>
  <div class="cluster ">
    <div class="container">
      <div class="row clusterBody align-items-center vh-100">

        <div class="introContainer col-xl-4 col-12 align-self-center">
          <div class="colInnerWrapper">
            <div class="slideCaption">
              <div class="introContent text-focus-in delay-05">
                <back-button />
                <h1>{{ plan.title }}</h1>
                <h3>{{ plan.sub_title }}</h3>
                <p>{{ plan.description }}</p>

                <h4 style="width:100%;"></h4>
                <!--- Play Call to Action --->
                <template v-for="(dis, index) in plan.districts" :key="dis.id">
                  <template v-for="cta in dis.gallery" :key="cta.id">
                    <button v-if="index === 0 && cta.type === 'left'" class="cta" data-toggle="modal" :data-target="'#modal'+cta.id">
                      <span class="icn"><img width="40" height="40" src="@/assets/images/icons/Play.svg" alt="How to get there"/></span>
                      <span class="txt"> {{ cta.call_to_action }}</span>
                    </button>

                    <teleport v-if=" cta.type === 'left' " to="body">
                      <modal :data="cta"></modal>
                    </teleport>
                  </template>
                </template>
                <!--- Play Call to Action --->
              </div>
            </div>
            <div class="colFooter text-focus-in delay-1">
              <h3 class="caption">{{ plan.secondary_title }}</h3>
              <h1 class="year">{{ plan.secondary_description }}</h1>
            </div>
          </div>
        </div>

        <div v-if="plan.type === 'simple' " class="imageContainer col-xl-8 col-12 justify-content-center puff-in-left align-self-center">
          <div class="row">
            <template v-for="(dis, index) in plan.districts" :key="dis.id">
              <template v-if="index === 0">
                <div :class="dis.progress != 0 ? 'col-xl-9 col-12' : 'col-xl-12 col-12' ">
                  <img
                      ref="imMV"
                      :src="getImage(plan.master_image,'general')"
                      alt="Mountain View MasterPlan"
                      class="slideImage"
                      @mouseenter=" singleHover=plan.slug "
                      @mouseleave=" singleHover='' "
                  />
                </div>

                <progressMeter v-if="dis.progress != 0 " :stageActive="toArray(dis.progress)" class="col-xl-3 col-12 justify-content-center align-self-center" />
                <popover
                    :pTitle="plan.name"
                    :status="dis.district_info"
                    :CTA="dis.gallery"
                    :color="dis.color"
                    :position="['15%','15%']"
                    v-show="singleHover === plan.slug"
                    @mouseenter=" singleHover=plan.slug "
                    @mouseleave=" singleHover='' "
                />
                <template v-for="gallery in dis.gallery" :key="gallery.id">
                  <teleport to="body" v-if="gallery.type === 'popover'">
                      <modal :data="gallery"></modal>
                  </teleport>
                </template>

              </template>
            </template>


          </div>
        </div>

        <div v-if="plan.type === 'complex' " class="imageContainer col-xl-8 col-12 justify-content-center puff-in-left align-self-center">
          <div class="row">
            <template v-for="(dis,index) in plan.districts" :key="dis.id" >

              <div v-if="index === 0" :class="dis.progress != 0 ? 'col-xl-9 col-12' : 'col-xl-12 col-12' ">
                <img
                    ref="imMV"
                    :src="getImage(plan.master_image,'general')"
                    id="plan_img"
                    alt="Mountain View MasterPlan"
                    class="slideImage"
                    style="max-width:100% !important"
                />

                <div class="svgContainer">
                  <svg id="plan_svg" viewBox=" 0 0 1444 944 " fill="none" xmlns="http://www.w3.org/2000/svg">
                    <template v-for="(dis, index) in plan.districts" :key="dis.id">
                      <g
                          :id="dis.name.replace(/\s/g, '')"
                          @mouseenter=" hover=dis.name.replace(/\s/g, ''); meterVal= toArray(dis.progress) "
                          @mouseleave="hover=''"
                          v-if="index !== 0"
                          v-html="dis.svg_path"
                          v-bind="{fill:dis.color, stroke:dis.color}">

                      </g>
                    </template>
                  </svg>
                </div>

                <div class="container">
                  <ul class="projectsList">
                    <template v-for="(dis,index) in plan.districts" :key="dis.id" >
                      <li v-if="index !== 0">
                        <span class="circle" :style="'color:'+dis.color">●</span><span class="proName">{{ dis.name }}</span>
                      </li>
                    </template>
                  </ul>
                </div>


              </div>
              <progressMeter v-if="index === 0 && dis.progress != 0 " :stageActive="meterVal" class="col-xl-3 col-12 justify-content-center align-self-center" />
            </template>
<!--            {{ plan.districts }}-->


            <!-- Popover -->
            <template v-for="(dis, index) in plan.districts" :key="dis.id">
              <template v-if="index !== 0">
                <popover
                    :pTitle="dis.name"
                    :status="dis.district_info"
                    :CTA="dis.gallery"
                    :color="dis.color"
                    :position="[
                              (dis.left_pos) ?  dis.left_pos+'%'  : '' ,
                              (dis.top_pos) ?  dis.top_pos+'%'  : '' ,
                              (dis.right_pos) ?  dis.right_pos+'%'  : '' ,
                              (dis.bottom_pos) ?  dis.bottom_pos+'%'  : ''
                              ]"
                    v-if="hover === dis.name.replace(/\s/g, '') && dis.district_info.length !== 0"
                    @mouseenter=" hover = dis.name.replace(/\s/g, ''); "
                    @mouseleave="hover=''"
                />
                <template v-for="gallery in dis.gallery" :key="gallery.id">
                  <teleport to="body" v-if="gallery.type === 'popover'">
                    <modal :data="gallery"></modal>
                  </teleport>
                </template>
              </template>
            </template>


          </div>
        </div>

      </div>
    </div>
  </div>

  <circles
      topPos1="50vh"
      topPos2="-10vh"
      leftPos1="-20vw"
      leftPos2="70vw"
  />
</template>
<script>

import {mapGetters} from "vuex";
import progressMeter from "@/components/general/progressMeter.vue";
import popover from '@/components/general/popover.vue';

export default{
  data: function () {
    return {
      singleHover:'',
      hover:'',
      meterVal:[],
      image:[],
    }
  },
  beforeCreate() {
    // console.log('test: ' + 'hyde')
    this.$store.dispatch('loadProject',{domain:'hyde'});
    this.$store.dispatch('loadNotification',{domain:'hyde'});
    this.$store.dispatch('loadPlans', {domain: 'hyde'});
  },
  renderTracked() {
    this.updateSVG();
  },
  components:{
    progressMeter,
    popover
  },
  computed:{
    ...mapGetters(['getImage']),
    plan(){
      return this.$store.getters.getPlan(this.$route.params.id);
    },
  },
  methods:{
    toArray(value){
      this.meterVal = value.split(',')
      return this.meterVal
    },
    updateSVG(){
      const img = new Image();
      img.onload = function() {
        // console.log(this.width + 'x' + this.height);
        let viewBox = '0 0 '+this.width+' '+this.height;

        document.getElementById('plan_svg').setAttribute('viewBox',viewBox);
      }
      img.src = this.getImage(this.plan.master_image,'general');
    }
  },
  watch:{
    hover(newValue,oldValue){
      newValue ? document.getElementById(newValue).style.opacity = 1 : document.getElementById(oldValue).style.opacity = 0.3 ;
    }
  }
}
</script>


<style scoped>


.svgContainer {
  position: absolute;
  width: 97%;
  height: auto;
  top: 0;
  display:flex;
  justify-content: center;
}
.svgContainer svg {
  width:100%;
  height: 100%;
}
.svgContainer svg g {
  opacity: 0.3;
  cursor: pointer;
}


.slideCaption{
  margin-top:15vh;
}
.colFooter {
  margin-top: 5vh;
}
.introContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}
.clusterBody .imageContainer .row{
  align-items:center;
}
.slideImage {
  max-width: 70% !important;
  margin: auto;
  display: block;
  transition:all 0.5s ease;
  -webkit-filter: drop-shadow(2px 4px 74px rgba(68, 103, 39, 0.85));
  filter: drop-shadow(2px 4px 74px rgba(68, 103, 39, 0.85));
}
.slideImage.puCDx{max-width: 100% !important;}
.slideImage:hover{
  transform:scale(1.1);
  -webkit-filter: drop-shadow(0 4px 34px rgba(68, 103, 39, 0.85));
  filter: drop-shadow(0 4px 34px rgba(68, 103, 39, 0.85));
}

.introContent h4{
  font-style:italic ;
  font-size:1.1rem;
}
.introContent h1{
  font-size:3rem;
}
.introContent p{
  font-size:1.15rem;
  font-weight:600;
}
.caption{
  font-size:1rem;
  font-weight:700;
}
.year{
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.5;
  display:block;
}
/***** Sub title UI *****/
#cd h4 {
  font-style:italic ;
  font-size:1rem;
}
#cd h1 {
  margin: 0;
}
.clusterBody .popover.puCDx {
  max-width: 436px !important;
  width: 326px !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/************     Responsive      *************/

/* Project List */
.projectsList {
  width: 100%;
  display: inline-grid;
  margin: 0.85rem auto 0;
  justify-content: center;
  grid-gap: 8px;
  grid-template-columns: repeat(3, 1fr);
}
.projectsList .circle {
  font-size: 2rem;;
  display: inline-block;
  margin-right: 15px;
  max-width: 14px;
  line-height: 14px;
}
.projectsList li {
  display: flex;
  cursor:pointer;
  align-items: center;
}
.projectsList li span {
  flex-grow: 1;
  font-family: "Lexend", sans-serif;
  font-size: 1.25rem;
  line-height: 16px;
  color: var(--mainColor);
}

.projectsList span.proName{
  margin-top:4px;
}
</style>